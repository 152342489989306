import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from '../helper/auth';

export const PrivateRoute = ({ component: Component, roles, socket, pathname, ...rest }) => (
    
    <Route {...rest} render={props => {
        props = Auth.currentUser();
        props.socket = socket;
        //console.log(props);
        // check if route is restricted by role
        if (roles && roles.indexOf(pathname) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ path: '/'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

