const context = require.context('../logos', true);

const obj = {};

context.keys().forEach((key)=>{
    const logoname = key.split('./').pop() // remove the first 2 characters
    .substring(0, key.length - 6); // remove the file extension
    obj[logoname] = context(key);
});

export default obj;