import React, { Component } from 'react';
import { Col, Card, CardText, CardBody, CardTitle, CardSubtitle, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Row } from 'reactstrap';
//import { Dropdown } from 'semantic-ui-react';
import "../App.css";
import Auth from '../helper/auth';
import { isNumber } from 'util';
import { ESLUrl, ServerUrl } from '../config/apiconfig';
import { domainToASCII } from 'url';

const axios = require('axios');
const longbeachlogo = require("../media/longbeach.png");
const longbeachlogosmall = require("../media/longbeachsmall.png");

export class Meters extends Component {
    constructor(props){
        super(props);

        this.state = {
            "meters": [],
            "selected": {}
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.meters !== this.props.meters){
            this.setState({meters: newProps.meters})
        }
    }

    componentDidMount() {
      if (this.state.meters !== this.props.meters) {
        this.setState({meters: this.props.meters})
      }
    }

    render() {
        return(<div>
          <ListGroup className="metersListGroup">
            <ListGroupItem color="info">Meters: </ListGroupItem>
            {this.state.meters.map(meter => <ListGroupItem className="metersListGroupItem" onClick={this.props.selectionChange} action>{meter.name}</ListGroupItem>)}
          </ListGroup>
        </div>
        )
    }
}

export class Scheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meterName: "",
            meterAgency: this.props.agency,
            meterMax: 0.0,
            meterMin: 0.0,
            flowMessage: "Please enter a requested flow value.",
            meterFlow: "Loading...",
            meterLastFlow: "Loading...",
            meterScheduleDate: "",
            meterScheduleTime: "",
            meterCFS: null,
            meterMGD: null,
            meterOldCFS: null,
            meterOldMGD: null,
            meterChangeCFS: null,
            meterChangeMGD: null,
            comments: "",
            meterNames: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.validateFlow = this.validateFlow.bind(this);
    }

    componentWillMount(){
      this.getMeterList();
    }

    componentDidMount() {
    }

    componentDidUpdate(previousProps, previousState){
      if(previousState.meterOldCFS != this.state.meterOldCFS || previousState.meterChangeCFS != this.state.meterChangeCFS){
        let cfsSum = Number(this.state.meterOldCFS) + Number(this.state.meterChangeCFS);
        let mgdSum = (Number(this.state.meterOldMGD) + Number(this.state.meterChangeMGD)).toFixed(1);
        //this.setState({meterCFS: cfsSum == 0 ? cfsSum : cfsSum, meterMGD: mgdSum == 0.0 ? '' : mgdSum});
        this.setState({meterCFS: cfsSum , meterMGD: mgdSum});
      }
    }

    getMeterList(){
      //setup for cal water
      var agencyArray = ["Cal Water - Palos Verdes", "Cal Water - Hermosa", "Cal Water  Hawthorne", "Dominguez"];

      if(agencyArray.includes(this.state.meterAgency)){
        this.getAllCalWaterMeter()
          .then(([PalosVerdes, Hermosa, Hawthorne, WestBasin2B, Dominguez])=>{
            var allMeters = [...PalosVerdes, ...Hermosa, ...Hawthorne, ...WestBasin2B,...Dominguez];
            allMeters.sort((a,b)=>(a.MeterID > b.MeterID) ? 1 : -1)
            allMeters.map((meter, i)=>{
              this.setState(state => ({
                  meterNames: [...state.meterNames, { id: i, name: meter.MeterID, maxCap: meter.MaxCap }]  //spread operator
              }))

              if(i==0){
                this.setState({meterName : meter.MeterID})
                this.getCurrentFlow(meter.MeterID);
                this.getLastRequestedFlow(meter.MeterID);
              }
            })
          })
      }else if (this.state.meterAgency =="Three Valleys"){
        this.getThreeValleys()
        .then(([GoldenState, Pomona])=>{
          var allMeters = [...GoldenState, ...Pomona];
          allMeters.sort((a,b)=>(a.MeterID > b.MeterID) ? 1 : -1)
          allMeters.map((meter, i)=>{
            this.setState(state => ({
                meterNames: [...state.meterNames, { id: i, name: meter.MeterID, maxCap: meter.MaxCap }]  //spread operator
            }))

            if(i==0){
              this.setState({meterName : meter.MeterID})
              this.getCurrentFlow(meter.MeterID);
              this.getLastRequestedFlow(meter.MeterID);
            }
          })
        })
      }else{
        fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/${this.props.agency}?type=json`)
          .then(res => res.json())
              .then(data => data.map((meter, i) => {
                  this.setState(state => ({
                      meterNames: [...state.meterNames, { id: i, name: meter.MeterID, maxCap: meter.MaxCap }]  //spread operator
                  }))

                  if(i==0){
                    this.setState({meterName : meter.MeterID})
                    this.getCurrentFlow(meter.MeterID);
                    this.getLastRequestedFlow(meter.MeterID);
                  }
              }
            )
        )
      }
    }
    //The following functions is dedicated to api calls for getting meters for the cal water group
    getPalosVerdes(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/${encodeURIComponent('Cal Water - Palos Verdes')}?type=json`)
      .then(res => res.json())
    }

    getHermosa(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/${encodeURIComponent('Cal Water - Hermosa')}?type=json`)
          .then(res => res.json())
    }

    getHawthorne(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/${encodeURIComponent('Cal Water  Hawthorne')}?type=json`)
          .then(res => res.json())
    }

    getWestBasin2B(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/${encodeURIComponent('West Basin')}?type=json`)
      .then(res => res.json())
      .then(data => {
        for(let i=0; i<data.length;i++){
          if(data[i].MeterID == 'WB-02B'){
            return [data[i]]; //return as an array so we can use spread function
          }
        }
      })
    }

    getDominguez(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/Dominguez?type=json`)
      .then(res => res.json())
    }

    getAllCalWaterMeter(){
      return Promise.all([this.getPalosVerdes(),this.getHermosa(),this.getHawthorne(),this.getWestBasin2B(),this.getDominguez()]);
    }
      /*fetch(`https://prod-05.usgovtexas.logic.azure.us:443/workflows/b536dbf7052242f3a66ca68ee99a7444/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=udUGbn_w6bOvx2D0aELjGaqfPobUzK30Ko75oNHtwhk`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({"agency": this.props.agency})
      })
      .then(res => res.json())
          .then(data => data.map((meter, i) => {
              this.setState(state => ({
                  meterNames: [...state.meterNames, { id: i, name: meter.MeterID, maxCap: meter.MaxCap }]  //spread operator
              }))

              if(i==0){
                this.setState({meterName : meter.MeterID})
                this.getCurrentFlow(meter.MeterID);
              }
          }
        )
    );*/

    getPomona(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/Pomona?type=json`)
      .then(res => res.json())
    }

    getGoldenState(){
      return fetch(`https://webservices.mwdsc.org/wins/Public/api/wometer/Golden%20State%20-%20%2310?type=json`)
      .then(res => res.json())
    }

    getThreeValleys(){
      return Promise.all([this.getGoldenState(),this.getPomona()]);
    }    

    getCurrentFlow(meterID){
      //search the array of meter objects for object by meter id
      var result = this.state.meterNames.find(meter=>{
        return meter.name === meterID
      })

      fetch(`https://webservices.mwdsc.org/wins/Public/api/wocurrentflow/${result.name}?type=json`,{
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
          .then(data => {
              //console.log(`The current flow for the selected meter is ${data}`)
              this.setState({meterFlow: Number(data[0].Flow).toFixed(1)})
          });

      //https://webservices.mwdsc.org/eslapi/meters/${result.name}
      fetch(`${ESLUrl}/eslapi/meters/${result.name}`)
      .then(res=>{
        //console.log(res.status);
            if(res.status === 200){
              return res.json()
            }
          }
        )
        .then(data=>{
          //console.log(data);
          this.setState({meterLastFlow: Number(data.Value).toFixed(1)})
        }).catch(error=>{
          //console.log("failed");
          this.setState({meterLastFlow: '0.0' })
        })

      this.setState({meterMax: Number(result.maxCap).toFixed(1), meterMin: Number(result.maxCap/10).toFixed(1), meterName:meterID});
    }

    getLastRequestedFlow(meterID){
      var result = this.state.meterNames.find(meter=>{
        return meter.name === meterID
      })

      //use ${ServerUrl} in url
      axios.get(`/api/findrecords/${result.name}`, {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Auth.getToken()
         }
      }).then(response=>{
        if(response.data){
          this.setState({meterOldCFS: Number(response.data.cfsValue), meterOldMGD: Number((response.data.cfsValue / 1.5472286365101).toFixed(1))})
        }else{
          this.setState({meterOldCFS: '', meterOldMGD: '', meterChangeCFS: '', meterChangeMGD: ''});
        }
      })

      /*fetch(`${ServerUrl}/api/findrecords/${result.name}`)
      .then(res=>{
        if(res.status === 200){
          return res.json()
        }
      }).then(data=>{
        console.log(data);
      })*/
    }

    meterChange(e){
      this.getCurrentFlow(e.target.value);
      this.getLastRequestedFlow(e.target.value);
    }

    validateForm() {
        //ABLE TO STORE ADDITIONAL DATA IN THE DATABASE ABOUT EVERY SINGLE METER
        //LIKE ACCEPTABLE RANGE FOR CFS, ETC, AND PASS IT IN AS PROPS AND VALIDATE AGAINST IT HERE
        if (this.state.meterScheduleDate && this.state.meterScheduleTime && (this.state.meterCFS || this.state.meterMGD) && this.validateFlow() && this.state.meterChangeCFS && this.state.meterChangeMGD) {
          return true
        }else{
          return false
        }
      }

    validateFlow() {
      //console.log(this.state.meterCFS);

      if(this.state.meterCFS == null){
        return true;
      }

      if(this.state.meterCFS == 0){
        return true;
      }
      
      if (parseFloat(this.state.meterCFS) >= parseFloat(this.state.meterMin) && parseFloat(this.state.meterCFS) <= parseFloat(this.state.meterMax)) {
        //this.setState({flowMessage: `The flow ${this.state.meterCFS} is VALID because it is in between the minimum value of ${this.state.meterMin} and the maximum value of ${this.state.meterMax}`})
        return true;
      }
      else {
        //this.setState({flowMessage: `The flow ${this.state.meterCFS} is INVALID because it is not in between the minimum value of ${this.state.meterMin} and the maximum value of ${this.state.meterMax}`})
        return false;
      }
    }

    resetForm(){
      //this.setState({meterScheduleDate: "", meterScheduleTime: "", meterCFS: 0, meterMGD: 0, comments: ""});
      this.getLastRequestedFlow(this.state.meterName);
      this.setState({meterChangeCFS:'',meterChangeMGD:'', meterScheduleDate: '', meterScheduleTime: ''})
    }

    handleFlowChange = event => {
        this.setState({
          [event.target.id]: event.target.value
          // example "cfs" : 36 -- or current value
        });

        const val = this.decimalNumber(event.target.value);

        //console.log(val);

        /*if (event.target.id === "meterCFS") {
          this.setState({meterMGD: (event.target.value / 1.5472286365101).toFixed(1), meterCFS: val}) 
        }
        else if (event.target.id === "meterMGD") {
          this.setState({meterCFS: (event.target.value * 1.5472286365101).toFixed(1)})
        }*/

        /*if (event.target.id === "meterOldCFS") {
          this.setState({meterOldMGD: (event.target.value / 1.5472286365101).toFixed(1), meterOldCFS: val}) 
        }
        else if (event.target.id === "meterOldMGD") {
          this.setState({meterOldCFS: (event.target.value * 1.5472286365101).toFixed(1)})
        }

        if (event.target.id === "meterChangeCFS") {
          this.setState({meterChangeMGD: (event.target.value / 1.5472286365101).toFixed(1), meterChangeCFS: val}) 
        }
        else if (event.target.id === "meterChangeMGD") {
          this.setState({meterChangeCFS: (event.target.value * 1.5472286365101).toFixed(1)})
        }*/

        if (event.target.id === "meterOldCFS") {
          this.setState({meterOldMGD: (event.target.value / 1.5472286365101).toFixed(1), meterOldCFS: val}) 
        }
        else if (event.target.id === "meterOldMGD") {
          this.setState({meterOldCFS: (event.target.value * 1.5472286365101).toFixed(1), meterOldMGD: val})
        }

        if (event.target.id === "meterChangeCFS") {
          this.setState({meterChangeMGD: (event.target.value / 1.5472286365101).toFixed(1), meterChangeCFS: val}) 
        }
        else if (event.target.id === "meterChangeMGD") {
          this.setState({meterChangeCFS: (event.target.value * 1.5472286365101).toFixed(1), meterChangeMGD: val})
        }

      }
    
    decimalNumber(num){
      if(num.length == 0){
        return num;
      }else if(num.indexOf('.') == '-1'){
        return Number(num);
      }else{
        return parseFloat(num).toFixed(1);
      }
    }

    handleInputs = event => {
      this.setState({
        [event.target.id]: event.target.value
      })
    }
    
    handleSubmit = () => {
      //This will submit the current schedule dialog values as a new record in the application database.
      let request = {};
      request.meter = this.state.meterName;
      request.agency = this.state.meterAgency;
      request.cfsValue = this.state.meterCFS;
      request.mgdValue = this.state.meterMGD;
      request.scheduleDate = this.state.meterScheduleDate;  //needs to be updated to allow date + time
      request.scheduleTime = this.state.meterScheduleTime;
      request.scheduleDateTime = new Date(this.state.meterScheduleDate + 'T' + this.state.meterScheduleTime + ':00');
      request.validFlow = this.validateFlow();
      request.requestor = Auth.currentUser().name;
      request.requestorEmail = this.props.email;
      request.comments = this.state.comments;
      request.phone = Auth.currentUser().telephone;
      request.lastFlow = this.state.meterOldCFS;
      this.setState({theRequest: this.props.request});
      this.props.getRequest(request);
    }

    setDate(){
      var date = new Date();
      var isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
      return isoDate.slice(0,10);
    }

    render(){
        let flowRender;
        let lastFlowRender;

        if (this.state.meterFlow === "Loading...") {
          flowRender = <Spinner size="sm" color="secondary"/>
        }
        else {
          flowRender = this.state.meterFlow;
        }

        if (this.state.meterLastFlow === "Loading...") {
          lastFlowRender = <Spinner size="sm" color="secondary"/>
        }
        else {
          lastFlowRender = this.state.meterLastFlow;
        }

        return(
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <Card style={{marginTop: '10px'}}>
              <CardBody>
              <Form id="scheduleForm" className="requestform" onSubmit={this.handleSubmit}>
                <h3 className="requestheader">Schedule Flow Change</h3>
                <FormGroup className="requestinputgroup" bssize="small">
                  <Label>Meter:</Label>
                  {" "}
                  <select onChange={this.meterChange.bind(this)}>
                    {this.state.meterNames.map(meter=>{
                      return <option key={meter.name} value={meter.name}>{meter.name}</option>
                    })}
                  </select>
                </FormGroup>
                  <pre>Current Flow (CFS):  {flowRender}</pre>
                  <pre>Last Requested Flow: {lastFlowRender}</pre>
                <FormGroup className="requestinputgroup" bssize="small">
                  <Label>Date</Label>
                  <Input
                    autoFocus
                    type="date"
                    min={this.setDate()}
                    onChange={this.handleInputs}
                    id="meterScheduleDate"
                    value={this.state.meterScheduleDate}
                  />
                </FormGroup>
                <FormGroup className="requestinputgroup" bssize="small">
                <Label>Time</Label>
                  <Input
                    type="time"
                    onChange={this.handleInputs}
                    id="meterScheduleTime"
                    value={this.state.meterScheduleTime}
                  />
                </FormGroup>
                <span style={{color:'red'}}>Min Flow(CFS): {this.state.meterMin}, Max Flow(CFS): {this.state.meterMax}</span>
                <Row>
                  <Col md="4">
                    <FormGroup className="requestinputgroup" bssize="small">
                      <Label>Old Flow - CFS </Label>
                      <Input
                        id="meterOldCFS"
                        placeholder="Enter CFS"
                        value={this.state.meterOldCFS}
                        onChange={this.handleFlowChange}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="requestinputgroup" bssize="small">
                      <Label>Change(+/-) - CFS </Label>
                      <Input
                        id="meterChangeCFS"
                        placeholder="Enter CFS"
                        value={this.state.meterChangeCFS}
                        onChange={this.handleFlowChange}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="requestinputgroup" bssize="small">
                      <Label>New Flow - CFS</Label><br />
                      <Input
                        id="meterCFS"
                        placeholder="Enter CFS"
                        value={this.state.meterCFS}
                        onChange={this.handleFlowChange}
                        type="number"
                        min={this.state.meterMin}
                        max={this.state.meterMax}
                        className={this.validateFlow() ? 'validInput' : 'invalidInput'}
                      />
                      <p style={this.validateFlow() ? {color: 'red', fontWeight: 'bold', visibility: "hidden"}:{color: 'red', fontWeight: 'bold', visibility: "visible"}}>{this.validateFlow() ? 'Valid Flow' : 'Invalid Flow'}</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup className="requestinputgroup" bssize="small">
                      <Label>Old Flow - MGD</Label>
                      <Input
                        id="meterOldMGD"
                        placeholder="Enter MGD"
                        value={this.state.meterOldMGD}
                        onChange={this.handleFlowChange}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="requestinputgroup" bssize="small">
                      <Label>Change(+/-) - MGD</Label>
                      <Input
                        id="meterChangeMGD"
                        placeholder="Enter MGD"
                        value={this.state.meterChangeMGD}
                        onChange={this.handleFlowChange}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="requestinputgroup" bssize="small">
                      <Label>New Flow - MGD</Label>
                      <Input
                        id="meterMGD"
                        placeholder="Enter MGD"
                        value={this.state.meterMGD}
                        onChange={this.handleFlowChange}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="requestinputgroup" bssize="small">
                  <Label>Comments (Optional)</Label>
                  <Input
                    type="textarea"
                    onChange={this.handleInputs}
                    id="comments"
                    value={this.state.comments}
                  />
                </FormGroup>
                <FormGroup className="requestinputbuttongroup">
                <Button
                  className="schedulebutton"
                  bssize="large"
                  disabled={!this.validateForm()}
                  type="button"
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
                {' '}
                <Button
                  className="schedulebutton"
                  bssize="large"
                  type="button"
                  onClick={this.resetForm.bind(this)}
                >
                  Reset
                </Button>
                </FormGroup>
              </Form>
              </CardBody>
            </Card>
          </Col>
        )
    }
}

export class Confirmation extends Component {
  constructor(props) {
    super(props);

  }

  render(){
    let warning = "";
    if (this.props.request.validFlow === false) {
      warning = "The flow is outside of the acceptable range. If it is below the range, you may be charged at the rate for the minimum flow.  Requests above the maximum flow are impossible and will be denied."
    }

    let confirmText;
    confirmText = <div>
      <br /><p>Meter: {this.props.request.meter}</p>
      <br /><p>CFS: {this.props.request.cfsValue}</p>
      <br /><p>MGD: {this.props.request.mgdValue}</p>
      <br /><p>Schedule Date (YYYY-MM-DD) {this.props.request.scheduleDate}</p>
      <br /><p>Schedule Time (Military): {this.props.request.scheduleTime}</p>
      <br /><p>Comments: {this.props.request.comments}</p>
      <br /><p className="invalid">{warning}</p>
    </div>

    return (<div>
      <Modal isOpen={this.props.modal}>
        <ModalHeader>Submit the following request to the Metropolitan Control Center?</ModalHeader>
        <ModalBody>{confirmText}</ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.props.confirmClick}>Confirm</Button>
          <Button color="danger" onClick={this.props.confirmCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>)
  }
}

export class ErrorModal extends Component {
  constructor(props) {
    super(props);

  }

  render(){
    return (<div>
      <Modal isOpen={this.props.modal}>
        <ModalHeader>Bad Request</ModalHeader>
        <ModalBody>An error has occur with your request <br></br> Please contact Eagle Rock staff 213-217-6000.</ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.props.confirmCancel}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>)
  }
}

const over = {
  height: 800,
  overflow: 'overlay'
}

export class OutstandingRequests extends Component {
  constructor(props){
    super(props);

    this.sendDataBack = this.sendDataBack.bind(this);
    this.sendDeleteBack = this.sendDeleteBack.bind(this);
  }

  sendDataBack(data, requestid) {
    this.props.updateRequest(data, requestid);
  }

  sendDeleteBack(requestid){
    this.props.deleteRequest(requestid)
  }

  render() {
    return(
      <Col xs="12" sm="12" md="6" lg="6" xl="6">
        <div style={over}>
          {this.props.requests.map(request => <ORequest accepted={request.accepted} denied={request.denied} requestID={request.id} meterName={request.name} meterScheduleDate={request.scheduleDate}
          meterScheduleTime={request.scheduleTime} cfsValue={request.cfsValue} rebuttal={request.rebuttal} comments={request.comments} sendDataBack={this.sendDataBack} sendDeleteBack={this.sendDeleteBack}></ORequest>)}
        </div>
      </Col>
    )
  }
}

class ORequest extends Component {
  constructor(props){
    //console.log(props);
    super(props);
    this.state= {
      comments: "",
      requestID: ""
    };

    this.handleInputs = this.handleInputs.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.submitDelete = this.submitDelete.bind(this);    
    //console.log("the ID in the props is:" + this.props.requestID)
  }

  componentWillMount() {
    if (this.state.comments !== this.props.comments) {
      this.setState({comments: this.props.comments})
    }

    if (this.state.requestID !== this.props.requestID) {
      this.setState({requestID: this.props.requestID})
    }
  }

  handleInputs = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  submitUpdate() {
    this.props.sendDataBack(this.state.comments, this.state.requestID);
  }

  submitDelete() {
    this.props.sendDeleteBack(this.props.requestID)
  }

  render(){

    return(<div>

      <Card className={this.props.denied ? 'bg-warning' : 'bg-danger'} style={{marginBottom: '10px',marginTop: '10px'}}>
        <CardBody>
          <CardTitle><h3>{this.props.meterName} {this.props.denied ? "Denied" : ""}</h3></CardTitle>
          <CardSubtitle>{this.props.meterScheduleDate} at {this.props.meterScheduleTime}</CardSubtitle>
          <CardText>
            CFS: {this.props.cfsValue}<br />
            Comments: <Input
                  type="textarea"
                  id="comments"
                  onChange={this.handleInputs}
                  value={this.state.comments}
                />
            <br/> MWD Response: <Input
                  type="textarea"
                  id="comments"
                  disabled="true"
                  value={this.props.rebuttal}
                />
          </CardText>
          <Button onClick={this.submitUpdate} disabled={this.props.denied ? true : false}>Update</Button>
          {' '}
          <Button color="danger" disabled={this.props.denied ? true : false} onClick={this.submitDelete}>Delete</Button>
        </CardBody>
      </Card>
    </div>)
    
  }
}

export class AgencyImage extends Component {
  constructor(props){
    super(props);

    this.state = {
      image: ""
    }
  }

  componentWillMount() {
    if (this.props.agency === "Long Beach") {
      this.setState({
        image: longbeachlogo,
        smallImage: longbeachlogosmall
      })
    }
  }

  render(){

    const imageSources = `${this.state.image} 509w, ${this.state.smallImage} 200w`;
    return(
      <div>
        <img srcSet={imageSources} sizes="(max-width: 420px) 200px, 509px" />
      </div>
    )
  }
}

export default { Meters, Scheduler, Confirmation, OutstandingRequests, AgencyImage };
