import React, {Component} from 'react'

class AdminPage extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div>
                <h1>Admin Page</h1>
            </div>
        )
    }
    
}

export { AdminPage };