const Access = {
    Admin: ["homepage", "staffpage", "adminpage"],
    Staff: ["homepage","staffpage"],
    User: ["homepage"]
}

function getAccess(accessrole){
    return Access[accessrole.role];
}

function isAllowed(access, path){
    return access.includes(path);
}

export {
    getAccess,
    isAllowed
}