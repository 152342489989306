import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ErrorScreen from './Error';
import * as serviceWorker from './serviceWorker';
import authentication from '../src/b2creact/msal-b2c-react/lib/msal-b2c-react';
import decode from 'jwt-decode';
import Auth from './helper/auth';

/*
 *Initialization setting for B2C authentication  
 */
authentication.initialize({
    // optional, will default to this
    //instance: 'https://login.microsoftonline.com/tfp/', 
    instance: 'https://mwdsocalb2c.b2clogin.com/',
    // your B2C tenant
    tenant: 'mwdsocalb2c.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    //comment out below for dev signup custom policy
    //signInPolicy: 'B2C_1A_TOTP_SIGNUP_SIGNIN',
    //comment out below for prod signup custom policy
    //signInPolicy: 'B2C_1A_TOTP_SIGNUP_SIGNINPRO',
    //comment out: use below for user flow instead of custom policies
    signInPolicy: 'B2C_1_WaterOrdering_SignUpIn',
    // the policy to use for password reset
    //resetPolicy: 'B2C_1_WO_SSPR',
    resetPolicy: 'B2C_1_WaterOrdering_Reset',
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    //WINSB2C
    //applicationId: '754795ba-cc8a-4bf8-a621-cb4bee773c8e',
    applicationId: 'da177b18-05a7-4a54-b0ca-05530e71983e',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'sessionStorage',
    // the scopes you want included in the access token
    scopes: ['https://mwdsocalb2c.onmicrosoft.com/wo/user'],
    //scopes: ['https://WinsB2CDev.onmicrosoft.com/wort/read'],
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    //redirectUri: 'http://localhost:5001',
    //redirectUri: 'https://woss-dev.mwdh2o.com',
    //redirectUri: 'https://woss.mwdh2o.com',
    redirectUri: process.env.AppServer,
    // optional, the URI to redirect to after logout
    //postLogoutRedirectUri: 'http://localhost:5001',
    //postLogoutRedirectUri: 'https://woss-dev.mwdh2o.com',
    //postLogoutRedirectUri: 'https://woss.mwdh2o.com',
    postLogoutRedirectUri: process.env.AppServer,
    //default to true, set to false if you change instance
    validateAuthority: false
});

authentication.run(()=>{
    //we can decode the token here and inspect it
    //const decoded = decode(authentication.getAccessToken());
    //How app was originally created, passed props to the app component.
    ReactDOM.render(<App />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
