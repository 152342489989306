import React, { Component } from 'react';
import { Button, Container, Row, Col, Modal } from 'reactstrap';
import { Meters, Scheduler, Confirmation, OutstandingRequests, ErrorModal, AgencyImage } from './clientview';
//import { requestAPIServer } from '../config/servers.js';
import {ServerUrl} from '../config/apiconfig';
import Auth from '../helper/auth';
import EventSource from 'eventsource';
const axios = require('axios');
const mwdseal = require('../media/mwdseal.jpeg');
//const requestAPIServer = 'http://localhost:5001';

export class HomePage extends Component {
    constructor(props){
        super(props);
        this.state = {
            "meters": [],
            "selectedMeter": "",
            "selectedMeterMax": 0,
            "showScheduler": false,
            "showMetersList": true,
            "showConfirmation": false,
            "showButton": true,
            "showOutstanding": false,
            "showErrorModal": false,
            "request": {},
            "updateRequest": {},
            "outstandingRequests": [],
            "socket": this.props.socket,
            "listening": false
        }
        this.scheduleClick = this.scheduleClick.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        this.getRequest = this.getRequest.bind(this);
        this.startOver = this.startOver.bind(this);
        this.confirmCancel = this.confirmCancel.bind(this);
        this.confirmClick = this.confirmClick.bind(this);
        this.getOutstandingRequests = this.getOutstandingRequests.bind(this);
        this.updateRequest = this.updateRequest.bind(this);
        this.deleteRequest = this.deleteRequest.bind(this);
        //this.events = new EventSourcePolyfill(`${ServerUrl}/sse`,{headers: {'Authorization':'Bearer '+Auth.getToken()}});
    }

    componentWillReceiveProps(newProps) {
        if (newProps.meterNames !== this.props.meterNames){
            this.setState({meters: newProps.meterNames})
        }
    }

    componentWillMount(){
        this.getOutstandingRequests();
    }

    componentDidMount(){
        if(!this.state.listening){
            //use ${ServerUrl} in url
            var sse = new EventSource(`/sse`,{headers: {'Authorization':'Bearer '+Auth.getToken()}});
            
            /*sse.addEventListener('New Request',event=>{
                console.log(`${event.data}`);
                this.getOutstandingRequests();
            })*/

            sse.onmessage = (event) => {
                console.log(event.data);
                if(event.data == '"new request"'){
                    this.getOutstandingRequests();
                }

                if(event.data == '"completed request"'){
                    this.getOutstandingRequests();
                }

                if(event.data == '"updated request"'){
                    this.getOutstandingRequests();
                }
              };

            this.setState({listening: true});
        }
        
        /*setInterval(() => {
            this.getOutstandingRequests();
        }, 60000);*/
    }

    selectionChange = (e, data) =>{
        //Pass selected meter back up from Meters child.
        //console.log(`Select clicked! Value is ${data.value}`)
        let selected = this.state.meters.find(x => x.name === e.target.innerText);
        this.setState({
            selectedMeter: e.target.innerText,
            selectedMeterMax: selected.maxCap
          });
        //console.log(e.target.innerText);
    }

    scheduleClick(e){
        this.setState(prevState => ({
            showScheduler: !prevState.showScheduler,
            showButton: false,
            showMetersList: false
          }));
    }

    cancelClick() {
        this.setState( { 
            //showScheduler: false,
            //showMetersList: true,
            showConfirmation: false,
            showButton: true
         })

         document.getElementById('requestform').reset();
    }

    confirmClick() {
        //this is where we submit the POST via axios to our Express backend, which ultimately ends up in MongoDB
        //we can view the request here by using state
        //use ${ServerUrl} in url
        axios.post(`/api/requests`, this.state.request, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
             }
        })
        .then(response => {
            //emit that a new request has been added
            //this.state.socket.emit('new request', this.state.request)
            //use ${ServerUrl} in url
            axios.post(`/api/emailrequest`, this.state.request, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Auth.getToken()
                 }
            })
            //this.getOutstandingRequests();
        })
        .catch(err => {
            var errmsg;
            if(err.response){
                errmsg = "Data: " + err.response.data + " Status: " + err.response.status + " Headers: " + err.response.headers;
            }else if(err.request){
                errmsg = "Request made but no response was received: " + err.request;
            }else{
                errmsg = err.message;
            }

            //use ${ServerUrl} in url
            axios.post(`/api/emailadmin`,{title: "WOSS Client Error", err:errmsg},{
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Auth.getToken()
                 }
            });

            this.setState({showErrorModal: true})
        });

        this.setState( {
            showScheduler: false,
            showConfirmation: false,
            showMetersList: true,
            showButton: true
        })
    }

    confirmCancel() {
        this.setState( {
            showConfirmation: false,
            showErrorModal: false,
            showScheduler: true
        })
    }

    getRequest(e) {
        //get the request and update the state
        this.setState( {
            request: e,
            showScheduler: false,
            showMetersList: false,
            showConfirmation: true
        })
    }

    getOutstandingRequests() {
        this.setState({
            outstandingRequests: []
        });
        const agencyuri = encodeURIComponent(this.props.agency);
        //use ${ServerUrl} in url
        axios.get(`/api/agency/${agencyuri}`,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
             }
        }).then(res => res.data.map((response, i) => {
            if(response.completed !== true && response.denied !== true){
                this.setState(state => ({
                    outstandingRequests: [...state.outstandingRequests, { 
                        id: response._id, 
                        name: response.meter,
                        agency: response.agency,
                        cfsValue: response.cfsValue,
                        scheduleDate: response.scheduleDate,
                        scheduleTime: response.scheduleTime,
                        accepted: response.accepted,
                        comments: response.comments,
                        rebuttal: response.rebuttal,
                        denied: response.denied
                    }],
                    showOutstanding: true
                }));
            }
            /*var d = new Date(response.initDate);
            var diff = Math.abs(new Date() - d);
            var sec = Math.floor(diff/1000);
            var min = Math.floor(sec/60);
                if(response.completed !== true && response.denied !== true){
                    this.setState(state => ({
                        outstandingRequests: [...state.outstandingRequests, { 
                            id: response._id, 
                            name: response.meter,
                            agency: response.agency,
                            cfsValue: response.cfsValue,
                            scheduleDate: response.scheduleDate,
                            scheduleTime: response.scheduleTime,
                            accepted: response.accepted,
                            comments: response.comments,
                            rebuttal: response.rebuttal,
                            denied: response.denied
                        }],
                        showOutstanding: true
                    }));
                }else if(response.denied === true && min < 70){
                    this.setState(state => ({
                        outstandingRequests: [...state.outstandingRequests, { 
                            id: response._id, 
                            name: response.meter,
                            agency: response.agency,
                            cfsValue: response.cfsValue,
                            scheduleDate: response.scheduleDate,
                            scheduleTime: response.scheduleTime,
                            accepted: response.accepted,
                            comments: response.comments,
                            rebuttal: response.rebuttal,
                            denied: response.denied
                        }],
                        showOutstanding: true
                    }));
                }*/
            }))
    }

    deleteRequest(id) {
        ////use ${ServerUrl} in url
        axios.delete(`/api/del/${id}`,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
             }
        }).then(res=>{
            //this.state.socket.emit('request delete', id);
            //this.getOutstandingRequests();
        })
        this.setState({
            showOutstanding: false
        })
    }

    updateRequest(comments, id){
        let updateRequest = {
            "_id": id,
            "acknowledged": "false",
            "completed": "false",
            "comments": comments
        }

        //use ${ServerUrl} in url
        axios.put(`/api/update`, updateRequest, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
             }
        })
        .then(response => {
            //console.log(response);
            //this.getOutstandingRequests();
        })
        .catch(err => console.log(err));
    }

    startOver() {
        this.setState({
            showScheduler: false,
            showMetersList: true,
            showConfirmation: false,
            showButton: true,
            selectedMeter: "",
            meters: this.props.meterNames,
            outstandingRequests: []
        })
    }

    render() {
        let displayButton;
        let displayScheduler;
        let displayMetersList;
        let displayConfirmation;
        let displayOutstanding = [];
        const sealSources = `${mwdseal} 400w, ${mwdseal} 200w`
    
        if (this.state.showMetersList === false) {
            displayMetersList = null;
        }
        else {
            displayMetersList = <Meters className="metersComponent" id="meterslist" meters={this.state.meters} selectionChange={this.selectionChange} ></Meters>
        }

        if (this.state.showScheduler === false) {
            displayScheduler = null;
        }
        else {
            displayScheduler = <Scheduler getRequest={this.getRequest} agency={this.props.agency} email={this.props.email} auth={this.props.auth} meterName={this.state.selectedMeter} meterMax={this.state.selectedMeterMax} cancelClick={this.cancelClick}></Scheduler>
        }

        if (!this.state.selectedMeter && this.state.showButton !== false) {
            displayButton = <Button className="selectbutton" disabled color="secondary" size="lg">Select a Meter</Button>;
        }
        else if (this.state.showButton === true) {
            displayButton = <Button className="selectbutton" onClick={this.scheduleClick} color="primary" size="lg">Schedule for {this.state.selectedMeter}</Button>;
        }
        else {
            displayButton = null;
        }

        if (this.state.showOutstanding === false) {
            displayOutstanding = null;
        }
        else {
            displayOutstanding = <OutstandingRequests requests={this.state.outstandingRequests} deleteRequest={this.deleteRequest} updateRequest={this.updateRequest}></OutstandingRequests>
        }

        const centerStyle = {
            justifyContent: 'center'
        }

        return(
            <div className="theDisplay">
                <Confirmation modal={this.state.showConfirmation} confirmClick={this.confirmClick} confirmCancel={this.confirmCancel} request={this.state.request}></Confirmation>
                <ErrorModal modal={this.state.showErrorModal} confirmCancel={this.confirmCancel}></ErrorModal>
                <Container>
                    <Row style={centerStyle}>
                        <Scheduler getRequest={this.getRequest} agency={this.props.agency} email={this.props.email} auth={this.props.auth}></Scheduler>
                        {displayOutstanding}
                    </Row>                    
                </Container>
            </div>
        )
    }
}

export default HomePage;