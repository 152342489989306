//const ServerUrl = "https://woss.mwdh2o.com";
//const ServerUrl = "https://woss-dev.mwdh2o.com";
const ServerUrl = "http://localhost:5001";
//const ServerUrl = process.env.AppServer;
const ESLUrl = "https://esl.mwd.h2o";
//const ESLUrl = "https://esl-dev.mwd.h2o";

module.exports = {
    ServerUrl,
    ESLUrl
}