import React, {Component} from 'react';
import Card from 'react-bootstrap/Card';

class AccountPage extends Component{
    render(){
        return(
            <Card style={{marginTop:'10px'}}>
                <Card.Body>
                    <p>
                        It may take a few minutes to create your new account.  
                        <br></br>
                        Once the process has been completed, you will be notified via email. 
                        <br></br>
                        It is now ok to close this window.  
                    </p>
                    <p>
                        If you need immediate access:
                        <br></br>
                        Contact Eagle Rock staff, if you are a MWD employee
                        <br></br>
                        All other users must contact your Water Ordering administrator for assistance.  
                    </p>
                </Card.Body>                
            </Card>
        )
    }
}

export{AccountPage}