import React, { Component } from 'react';
import "./staffpage.css";
import { RequestsTable, RebuttalModal, AcceptModal, DenyModal, CompleteModal, ErrorModal, LoadingModal, FacilityModal } from './staffview';
import {ServerUrl,ESLUrl} from '../config/apiconfig';
import { DateTime } from 'ews-javascript-api';
import Auth from '../helper/auth';
import {firstBy} from 'thenby';
import EventSource from 'eventsource';

const axios = require('axios');

export class StaffPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "requests": [],
            "showTable": false,
            "showRebuttalModal": false,
            "showDenyModal": false,
            "showAcceptModal": false,
            "showCompleteModal": false,
            "showErrorModal": false,
            "showLoadingModal": false,
            "errorMessage":"",
            "rebuttalID": "",
            "denyID": "",
            "acceptID": "",
            "completeID": "",
            "ignoreNotification": true,
            "socket": this.props.socket,
            "millsplant": false,
            "facility": "",
            "listening": false
        }
        this.queryRequests = this.queryRequests.bind(this);
        this.cleanUpRequests = this.cleanUpRequests.bind(this);
        this.showTable = this.showTable.bind(this);
        this.showRebuttalDialog = this.showRebuttalDialog.bind(this);
        this.showDenyModal = this.showDenyModal.bind(this);
        this.showAcceptModal = this.showAcceptModal.bind(this);
        this.showCompleteModal = this.showCompleteModal.bind(this);
        this.setAcknowledged = this.setAcknowledged.bind(this);
        this.setAccepted = this.setAccepted.bind(this);
        this.setDenied = this.setDenied.bind(this);
        this.setCompleted = this.setCompleted.bind(this);
        this.sendTheEmail = this.sendTheEmail.bind(this);
        this.senData = this.sendData.bind(this);
        this.padDate = this.padDate.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.closeErrorModal = this.closeErrorModal.bind(this);
        this.setMillsPlant = this.setMillsPlant.bind(this);
        this.setFacility = this.setFacility.bind(this);
        //this.events = new EventSource('http://localhost:5001/sse');
        //this.events = new EventSourcePolyfill(`${ServerUrl}/sse`,{headers: {'Authorization':'Bearer '+Auth.getToken()}});
        //this.events = new EventSource(`${ServerUrl}/sse`,{headers: {'Authorization':'Bearer '+Auth.getToken()}});
    }

    queryRequests() {
        //use this meter list is for separating the meters for mills and eagle rock.
        var millsMeterArray = ["WR-24A","WR-24E","WR-24D","WR-24C","EM-12A","EM-12B", "EM-12C", "EM-23"];

        var verifyRequests = [];
        //use ${ServerUrl} in url
        axios.get(`/api/`,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
            }
        })
        .then(res =>  {
            if(this.state.facility === "Mills"){
                var millsFiltered = res.data.filter(function(value,index,arr){
                    if(millsMeterArray.includes(value.meter)){
                        return value;
                    }
                })

                this.setState({requests:millsFiltered});
            }else if(this.state.facility === "OCC"){
                var eagleRockFiltered = res.data.filter(function(value,index,arr){
                    if(!millsMeterArray.includes(value.meter)){
                        return value;
                    }
                })
                //console.log(eagleRockFiltered);
                this.setState({requests:eagleRockFiltered});
            }
        })  
        
    }

    //sort the array so we stay consistant
    sortArray(rawArray){
        return rawArray.sort(firstBy('agency').thenBy('requestor').thenBy('scheduleDate').thenBy('id'));
    }

    cancelModal(){
        this.showTable();
    }

    cleanUpRequests() {
        //maybe we can invoke something here to solve the diffing issue? idfk 
    }

    componentWillMount() {
        //this.queryRequests();
    }

    componentDidMount() {
        if(!this.listening){
            //use ${ServerUrl} in url
            var sse = new EventSource(`/sse`,{headers: {'Authorization':'Bearer '+Auth.getToken()}});
            
            /*sse.addEventListener('New Request',event=>{
                console.log(`${event.data}`);
                this.queryRequests();
            })*/
            sse.onmessage = (event) => {
                //console.log(event.data);
                if(event.data == '"new request"'){
                    //notify the dashboard a new request came in
                    new Notification("Water Ordering",{
                        body: 'A new request came in',
                        dir: 'ltr',
                        requireInteraction: true
                    });
                    this.queryRequests();
                }else if(event.data == '"updated request"' || event.data == '"completed request"' || event.data == '"deleted request"'){
                    this.queryRequests();
                }
            };

            sse.onerror = (error) =>{
                console.log(error);
            }

            this.setState({listening:true});
        }

        /*
        *   Check and request permissions to use notification
        */
        if('Notification' in window){
            Notification.requestPermission();
        }

        /*
        *   Process for rearming the alarm, every interval, check if acknowledged is checked and if accepted is false,
        *   If those conditions are correct, then rearm the alarm
        */
        if(this.state.requests){
            setInterval(()=>{
                this.state.requests.map((request,i)=>{
                    if(request.acknowledged == true && request.accepted == false){
                        if(((Date.now()/1000)-request.acknowledgedTime) >= 60){
                            //console.log("hello time xpired");
                            this.setAcknowledged(request._id, request.comments, request.rebuttal, request.accepted, false)
                        }
                    }
                })
            },10000)
        }

        /**
         * Listen for these events from socket io
         * Request Delete - The client side has deleted a request
         * New Request - The client side created a new request
         * Request Updated - The client side updated a request
         */
        /*this.state.socket.on('request delete',(id)=>{
            let updateArray = this.state.requests.filter(el => el._id !== id);
            this.setState({requests: updateArray});
        });

        this.state.socket.on('request denied',(id)=>{
            let updateArray = this.state.requests.filter(el => el._id !== id);
            this.setState({requests: updateArray});
        });

        this.state.socket.on('new request', ()=>{
            this.queryRequests();
            new Notification("Water Ordering",{
                body: 'A new request came in',
                dir: 'ltr',
                requireInteraction: true
            });
        })*

        this.state.socket.on('request updated', ()=>{
            this.queryRequests();
        })

        this.state.socket.on('request completed', (id)=>{
            let updateArray = this.state.requests.filter(el => el._id !== id);
            this.setState({requests: updateArray});
        })

        this.state.socket.on('reconnect', ()=>{
            this.queryRequests();
        })*/
    }

    setAcknowledged(id, comments, rebuttal, accepted, checked) {
        let updateRequest = {
            "_id": id,
            "comments": comments,
            "rebuttal": rebuttal,
            "acknowledged": checked,
            "acknowledgedTime": Date.now()/1000,
            "accepted": accepted,
            "completed": "false",
            "denied": "false"
        }

        //use ${ServerUrl} in url
        axios.put(`/api/update`, updateRequest, {
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer ' + Auth.getToken()
            }
        })
        .then(response => {
            this.showTable();
        })
        .catch(err => console.log(err));
    }

    setAccepted(id, comments, rebuttal, acknowledged) {
        this.setState({showLoadingModal: true});

        let updateRequest = {
            "_id": id,
            "comments": comments,
            "rebuttal": rebuttal,
            "acknowledged": "true",
            "accepted": "true",
            "completed": "false",
            "denied": "false"
        }

        //use ${ServerUrl} in url
        axios.put(`/api/update`, updateRequest, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken() 
            }
        })
        .then(res=>{
            this.showTable();
            this.sendTheEmail(id, "approved");
        })
        .catch(err=>{
            this.setState({showErrorModal: true, showLoadingModal: false, errorMessage: "Failed to accept request.  Refresh page and try again."})
        });

    }

    setDenied(id, comments, rebuttal, acknowledged){
        let updateRequest = {
            "_id": id,
            "comments": comments,
            "rebuttal": rebuttal,
            "acknowledged": acknowledged,
            "accepted": "false",
            "completed": "false",
            "denied": "true"
        }

        //use ${ServerUrl} in url
        axios.put(`/api/update`, updateRequest, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken() 
            }
        })
        .then(response => 
            {
                //console.log(response);
                //update the array and remove the denied request from it
                let updateArray = this.state.requests.filter(el => el._id !== id);
                this.setState({requests: updateArray});
            }
        )
        .catch();

        this.showTable();
        this.sendTheEmail(id, "denied");
    }

    setCompleted(id){
        this.setState({showLoadingModal:true});

        let completeRequest = {
            "_id": id
        }

        //use ${ServerUrl} in url
        axios.get(`/api/findbyid/${id}`,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken() 
            }
        }).then(res=>{
            //find the last flow
            this.sendData({
                'id':id,
                'agency': res.data.agency,
                'created_by': "WOES MWD",
                'createDate': new Date().toISOString(),
                'requested_by': res.data.requestor,
                'requested_to': Auth.currentUser().name,
                'initDate': res.data.initDate,
                'event_date': res.data.scheduleDate,
                'event_time': res.data.scheduleTime,
                'meter_id': res.data.meter,
                'new_value': res.data.cfsValue,
                'old_value': res.data.lastFlow,
                'facilno': 1
            },completeRequest)
        }).catch(err => {})
        .then(res=>{
        })

        //this.showTable();
    }

    padDate(_date){
        return _date < 10 ? '0'+_date : _date
    }

    sendData(getData,compRequest) {
        //console.log(getData);
        let date = new Date(getData.initDate);
        //let dateComplete = new Date(getData.createDate)
        //pad the hour and minutes with leading zeros.  
        let requestedTime = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

        //Test for facility number
        var meterArray = ["WR-24A","WR-24E","WR-24D","WR-24C","EM-12A","EM-12B", "EM-12C", "EM-23"];

        var facilityNo = 1;

        if(meterArray.includes(getData.meter_id)){
            facilityNo = 4;
        }

        let eslData = Object.assign({}, {
            //'id': getData.id,
            'created_by': 'WOSS MWD',
            'created_date': getData.createDate.slice(0,10),
            'requested_by': 'WOSS MWD',
            'requested_to': getData.requested_to,
            'requested_date': getData.initDate.slice(0,10),
            'requested_time': requestedTime,
            'event_date': getData.event_date,
            'event_time': getData.event_time,
            'meter_id': getData.meter_id,
            'new_value': getData.new_value,
            'old-value': getData.old_value,
            'facilno': facilityNo
        })

        console.log(facilityNo);

        //var requestData = `"{'created_by': 'WOES MWD','created_date': '${getData.createDate.slice(0,10)}','requested_by': 'WOES MWD','requested_to': '${getData.requested_to}','requested_date': '${getData.initDate.slice(0,10)}','requested_time': '${requestedTime}','event_date': '${getData.event_date}','event_time': '${getData.event_time}','meter_id': '${getData.meter_id}','new_value': ${getData.new_value},'old-value': ${getData.old_value},'facilno': 1}"`;
        //console.log(JSON.stringify(eslData));
        axios.post(`${ESLUrl}/eslapi/wo`, '"'+JSON.stringify(eslData).replaceAll('"','\'')+'"', {
                headers: { 'Content-Type': 'application/json'}
            },
        ).then(res=>{
            //use ${ServerUrl} in url
            axios.put(`/api/requests/completed`, compRequest, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Auth.getToken() 
                }
            })
            .then(response => 
                {
                    //console.log(response)
                    //update the array and remove the completed request from it
                    this.sendTheEmail(getData.id, "completed");
                    let updateArray = this.state.requests.filter(el => el._id !== compRequest._id);
                    this.setState({requests: updateArray});
                    this.showTable();
                }
            )
            .catch(err=>{
                console.log(err);
            })
        })
        .catch(error=>{
            var errmsg;
            if(error.response){
                errmsg = "Data: " + error.response.data + " Status: " + error.response.status + " Headers: " + error.response.headers;
            }else if(error.request){
                errmsg = "Request made but no response was received: " + error.request;
            }else{
                errmsg = error.message;
            }

            //use ${ServerUrl} in url
            axios.post(`/api/emailadmin`, {title: "WOSS ESL Error",err:errmsg}, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
            }});

            this.setState({showErrorModal:true, showLoadingModal: false, errorMessage: 'Unable to record flow change request to ESL.  Refresh the page and try again or manually input request into ESL.'});
        });

        //this.showTable();
    }

    async getFlowAsync(agencyData) {
        let prvDate = new Date(agencyData.event_date);
        prvDate.setDate(prvDate.getDate()-1);

        //use ${ServerUrl} in url
        let result = await axios.get(`/api/findflow`, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken() 
            },
            params:{
                agency: agencyData.agency,
                meter: agencyData.meter_id,
                scheduleDate: prvDate.toISOString().substring(0,10)
            }
        }).then(response=>{
            return response.data.cfsValue
        })
        .catch(err=>{
            //console.log(err)
        })

        return result;
    }

    /**
     * Possibly I need to implement the deny function
     */
    sendTheEmail(id, origin) {
        console.log(id, origin);
        let notificationData = {};

        if (origin === "approved") {
            notificationData.subject = "Flow Change Request Approved";
            notificationData.event = "has been approved";
        }
        else if (origin === "denied") {
            notificationData.event = "has been denied. Please submit a new request or contact the Metropolitan Control Center";
            notificationData.subject = "Flow Change Request Denied";
        }
        else if (origin === "completed") {
            notificationData.event = "has been approved";
            notificationData.subject =  "Flow Change Request Approved";
        }

        //need to check to see what this email is for
        //use ${ServerUrl} in url
        axios.get(`/api/findbyid/${id}`,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken() 
            }
        })
        .then(res => Object.assign(notificationData, {
                meter: res.data.meter,
                time: res.data.scheduleTime,
                date: res.data.scheduleDate,
                flow: res.data.cfsValue,
                email: res.data.requestorEmail,
                requestor: res.data.requestor
        }))
        //use ${ServerUrl} in url
        .then(fullData => axios.post(`/api/email`, fullData, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken()
            }
        }))
    }


    showTable(){
        this.setState({
            showTable: true,
            showAcceptModal: false,
            showRebuttalModal: false,
            showDenyModal: false,
            showCompleteModal: false,
            showLoadingModal: false,
        })
    }

    showRebuttalDialog(id) {
        this.setState({
            showTable: false,
            showAcceptModal: false,
            showRebuttalModal: true,
            showDenyModal: false,
            showCompleteModal: false,
            rebuttalID: id
        })
    }

    showDenyModal(id) {
        this.setState({
            showTable: false,
            showAcceptModal: false,
            showRebuttalModal: false,
            showDenyModal: true,
            showCompleteModal: false,
            denyID: id
        })
    }

    showAcceptModal(id) {
        this.setState({
            showTable: false,
            showAcceptModal: true,
            showRebuttalModal: false,
            showDenyModal: false,
            showCompleteModal: false,
            acceptID: id
        })
    }

    showCompleteModal(id) {
        this.setState({
            showTable: false,
            showAcceptModal: false,
            showRebuttalModal: false,
            showDenyModal: false,
            showCompleteModal: true,
            completeID: id
        })
    }

    closeErrorModal(){
        this.setState({
            showErrorModal: false
        })
    }

    handleNotificationPermissionsGranted(){
        this.setState({
            ignoreNotification: true
        })
    }

    setMillsPlant(){
        if(this.state.millsplant){
            this.setState({millsplant: false},()=>{
                this.queryRequests();
            });
        }else{
            this.setState({millsplant: true},()=>{
                this.queryRequests();
            });
        }
    }

    setFacility(facility){
        if(facility === "OCC"){
            this.setState({facility: "OCC", showTable: true},()=>{
                this.queryRequests();
            })
        }else if(facility === "Mills"){
            this.setState({facility: "Mills", showTable: true},()=>{
                this.queryRequests();
            })
        }else if(facility === "Skinner"){
            this.setState({facility: "Skinner", showTable: true},()=>{
                this.queryRequests();
            })
        }
    }

    render() {
        let show;
        if (this.state.showTable === true) {
            //show = false;
            show = <RequestsTable setMills={this.setMillsPlant} showAcceptModal={this.showCompleteModal} showRebuttalDialog={this.showRebuttalDialog} showDenyModal={this.showDenyModal} showCompleteModal={this.showCompleteModal} setAcknowledged={this.setAcknowledged} requests={this.state.requests}></RequestsTable>
        }

        else if (this.state.showTable === false && this.state.showRebuttalModal === true) {
            //show = true;
            show = <RebuttalModal modal={true} rebuttalClick={this.setAcknowledged} rebuttalCancel={this.cancelModal} request={JSON.parse(this.state.rebuttalID)}></RebuttalModal>
        }

        else if (this.state.showTable === false && this.state.showDenyModal === true) {
            show = <div><DenyModal modal={true} denyClick={this.setDenied} denyCancel={this.showTable} request={JSON.parse(this.state.denyID)}></DenyModal></div>
        }

        else if (this.state.showTable === false && this.state.showAcceptModal === true) {
            show = <div><AcceptModal modal={true} acceptClick={this.setAccepted} acceptCancel={this.showTable} request={JSON.parse(this.state.acceptID)} meter={this.state.meter} scheduleDate={this.state.scheduleDate} scheduleTime={this.state.scheduleTime}></AcceptModal></div>
        }

        else if (this.state.showTable === false && this.state.showCompleteModal === true) {
            show = <div><CompleteModal modal={true} completeClick={this.setCompleted} completeCancel={this.showTable} request={JSON.parse(this.state.completeID)}></CompleteModal></div>
        }

        else if (this.state.showTable === false && this.state.facility === ""){
            show = <div><FacilityModal modal={true} setFacility={this.setFacility}></FacilityModal></div>
        }

        return(
            <div>
                <ErrorModal modal={this.state.showErrorModal} confirmCancel={this.closeErrorModal} message={this.state.errorMessage}></ErrorModal>
                <LoadingModal modal={this.state.showLoadingModal}></LoadingModal>
                {show}
            </div>
        )
    }

}

export default StaffPage;