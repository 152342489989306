import React, {Component, useEffect} from 'react';
import logo from './logo.svg';
import authentication from 'react-azure-adb2c';
import {BrowserRouter as Router, Switch, Link, NavLink} from 'react-router-dom';
import {PrivateRoute} from './routes/privateroute';
import {HomePage} from './component/homepagenew';
import {StaffPage} from './component/staffpage';
import {AdminPage} from './component/adminpage';
import {getAccess, isAllowed} from './helper/access';
import getLogo from './helper/logohelper';
import Auth from './helper/auth';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {AccountPage} from './component/accountsetpage';
import {ServerUrl} from './config/apiconfig';
import socketIO from 'socket.io-client';

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      'access' : [],
      'view': AccountPage,
      'agencylogo' : null,
    }
  }

  componentWillMount(){
    //console.log(getLogo[Auth.currentUser().agency]);
    
    if(Auth.currentUser().newUser && !Auth.currentUser().email.includes("mwdh2o")){
      fetch(`/api/account/create`,{
        method: 'post',
        headers: {
          'Content-Type':'application/json',
          'Authorization':'Bearer ' + Auth.getToken()
        },
        body: JSON.stringify({name: Auth.currentUser().name, email: Auth.currentUser().email, agency: Auth.currentUser().agency, userId: Auth.currentUser().userId})
      }).then(()=>{
        //let the user know their agency admin is notified and will need to approve their request
      })
    }else if(Auth.currentUser().newUser && Auth.currentUser().email.includes("mwdh2o")){
      fetch(`/api/staff/email`,{
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':'Bearer ' + Auth.getToken()
        },
        body: JSON.stringify({email: Auth.currentUser().email,name: Auth.currentUser().name})
      }).then(res=>{
        //let the user know, mwd staff is notified and needs to set up their account
        res.json()
      }).then(data=>{
        //console.log(data)
      })
    }

    //call the route to get the user group, pass in user id
    fetch(`/api/getauthorization/${Auth.currentUser().userId}`,{
      method: 'get',
      headers:{
        'Content-Type': 'application/json',
        'Authorization':'Bearer ' + Auth.getToken()
      }
    }).then(res=>
        res.json()
    ).then(data=>{
      //console.log(data);
      this.setState({access:getAccess(data)});
      if(data.role == "Staff"){
        this.setState({view: StaffPage})
      }else if(data.role == "User"){
        this.setState({view: HomePage})
      }
    })

    setInterval(()=>{
      var exp = (Auth.getTokenExpirationTime() * 1000)-60000;
      if(Date.now() >= exp){
        authentication.signOut();
      }
    },60000)
  }

  MobileScreen(){
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      // Take the user to a different screen here.
      return true;
    }
  }

  Logout(){
    authentication.signOut();    
  }

  

  render(){
    return(
      <Router>
        <Navbar className="navbar-dark bg-dark" expand="lg">
          <Navbar.Brand href="/">
            <img src={getLogo[Auth.currentUser().agency]} width="30" height="30" alt="" style={getLogo[Auth.currentUser().agency] ? null:{display:'none'}}/>
              Water Ordering
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="" onClick={this.Logout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container fluid>
          <Switch>
            <PrivateRoute exact path="/" component={this.state.view}/>
            <PrivateRoute path="/admin" roles={this.state.access} pathname={"adminpage"} component={AdminPage} />
          </Switch>
          <Container style={{bottom: 0, position: 'absolute', width: '100%', display: this.MobileScreen() ? 'none' : 'block'}}>
            <p>Version: 1.0.4</p>
          </Container>
        </Container>
      </Router>
    )
  }
}

export default App;