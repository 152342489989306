import auth from '../b2creact/msal-b2c-react/lib/msal-b2c-react';
import decode from 'jwt-decode';

const Auth = {
    isLoggedIn: function(){
        if(auth.getAccessToken()){
            return true;
        }

        return false;
    },

    getToken: function(){
        return auth.getAccessToken();
    },

    getTokenExpirationTime: function(){
        const decoded = decode(auth.getAccessToken());

        return decoded.exp;
    },

    currentUser: function(){
        const decoded = decode(auth.getAccessToken());
    
        return{
            name: decoded.given_name + ' ' + decoded.family_name,
            agency: decoded.city,
            email: decoded.emails[0],
            userId: decoded.sub,
            telephone: decoded.extension_Telephone,
            newUser: decoded.newUser ? decoded.newUser : false
        }
    }
}

export default Auth;