import React, { Component } from 'react';
import { Table, Button, Form, FormGroup, InputGroup, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import "../App.css";
import Image from '../media/submit.png';
import oldBell from '../sounds/oldbell.mp3';
import {ServerUrl} from '../config/apiconfig';
import {Container} from 'reactstrap';
import Auth from '../helper/auth';
import { JsonDeserializationNotImplementedException } from 'ews-javascript-api';
const axios = require('axios');

export class RequestsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: this.props.requests,
            updateComments: "",
            updateRebuttal: "",
            playAudio: false,
            //audio: new Audio(oldBell)
        }

        this.sendAcknowledgedID = this.sendAcknowledgedID.bind(this);
        this.sendAcceptedID = this.sendAcceptedID.bind(this);
        //this.handleSendClick = this.handleSendClick.bind(this);
        this.rebuttalClick = this.rebuttalClick.bind(this);
        this.sendDeniedID = this.sendDeniedID.bind(this);
        this.handleSendClick = this.handleSendClick.bind(this);
        this.getCurrentFlow = this.getCurrentFlow.bind(this);
        this.setFlowChangeValue = this.setFlowChangeValue.bind(this);
        this.setMillsValue = this.setMillsValue.bind(this);
        //this.playSound = this.playSound.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.requests !== this.props.requests){
            this.setState({requests: newProps.requests})
        }
    }

    sendAcknowledgedID(event) {
        var request = this.state.requests.find(x => x._id === event.target.id);
        //console.log("the comments in the request object in sendAcknowledgedID are: " + request.comments)
        this.props.setAcknowledged(event.target.id, request.comments, request.rebuttal, request.accepted, event.target.checked);
    }

    sendAcceptedID(event) {
        //var request = this.state.requests.find(x => x._id === event.target.id)
        //console.log("the comments in the request object in sendAcknowledgedID are: " + request.comments)
        //this.props.showAcceptModal(event.target.id, request.comments, request.rebuttal, request.acknowledged, event.target.checked);
        //this.props.showAcceptModal(event.target.id);
        this.props.showAcceptModal(event.target.id);
    }

    sendDeniedID(event) {
        this.props.showDenyModal(event.target.id);
    }

    handleSendClick(event) {
        //set up conditionals based on radio button selection:
        
        //var request = this.state.requests.find(x => x.id === id)
        this.props.showCompleteModal(event.target.id);
    }

    rebuttalClick(event) {
        this.props.showRebuttalDialog(event.target.id);
    }

    getCurrentFlow(meter){
        axios.get(`https://webservices.mwdsc.org/wins/Public/api/wocurrentflow/${meter}?type=json`,{
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            return res.data[0].Flow
        })
    }

    setFlowChangeValue(cfsValue, lastFlow){
        let diff = cfsValue - lastFlow;

        if(diff > 0){
            return lastFlow + '+' + diff + '=' + cfsValue;
        }else{
            return lastFlow +'-' + Math.abs(diff) + '=' + cfsValue;
        }
    }

    /*playSound(){
        this.state.audio.play();
    }*/

    setMillsValue(){
        this.props.setMills();
    }

    //set the class name for arming the alarm or if request has been acknowledged.
    setAlarmValue(acknowledge, meterID){
        var meterArray = ["A-07","A-08","B-06","BSF-0","CA-02","CB-01","CB-02","CB-09","CENB-28","CENB-48","CLWA","CM-01","CM-08",
                            "EM-14","EM-22","EM-23","LA-25","LA-35","OC-11","OC-12","OC-13","OC-13A","OC-28","OC-28A",
                            "OC-33","OC-33A","OC-36","OC-52","OC-59","OC-66","OC-67","OC-68","OC-69","OC-70",
                            "OC-71","OC-72","OC-74","OC-75","OC-76","OC-77","OC-79","OC-80","OC-81","OC-82",
                            "OC-88","OC-88A","PM-17","WR-02","WR-10","WR-12","WR-19","WR-27","WR-29","WR-33"];
        if(acknowledge){
            return 'seenrow';
        }else if(meterArray.includes(meterID)){
            return 'priorityrow';
        }else{
            return 'alarmrow';
        }
    }

    render() {
        /*var play = this.state.requests.find(x => x.acknowledged === false);
        var audio;

        if (play) {
            audio = <audio autoPlay loop>
                <source src={oldBell} type="audio/mpeg"/>
            </audio>
        }
        else {
            audio = null;
        }*/

        //remove this to go back to original state
        var audio = null;
        
        //className={request.acknowledged ? 'seenrow' : 'alarmrow'}
        return(
            //replacing div with container
            <Container fluid>
                {audio}
                <br />
                <Table dark>
                    <thead>
                        <tr>
                            <th>Agency</th>
                            <th>Requestor</th>
                            <th>Meter</th>
                            <th>Old Flow + Change = New Flow (CFS)</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Comments</th>
                            <th>Response (Optional)</th>
                            <th>Acknowledged</th>
                            <th>Approval</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.requests.map(request => <tr className={this.setAlarmValue(request.acknowledged, request.meter)}>
                            <td scope="row">{request.agency}</td>
                            <td>{request.requestor}<br></br>{request.phone}</td>
                            <td>{request.meter}</td>
                            <td>{this.setFlowChangeValue(request.cfsValue, request.lastFlow)}</td>
                            <td>{request.scheduleDate}</td>
                            <td>{request.scheduleTime}</td>
                            <td><Input type="textarea" disabled={true} value={request.comments}></Input></td>
                            <td><Input type="textarea" disabled={true} value={request.rebuttal}></Input><br/><Button id={JSON.stringify(request)} onClick={this.rebuttalClick} size="small" color="success">Update Response</Button></td>
                            <td><Input type="checkbox" className="biggercheckbox" disabled={request.accepted ? true : false} checked={request.acknowledged} id={request._id} onChange={this.sendAcknowledgedID}></Input></td>
                            <td>
                                <Button className="btn-block" color="primary" disabled={request.accepted} id={JSON.stringify(request)} onClick={this.sendAcceptedID}>Accept</Button>
                                <Button className="btn-block" color="danger" disabled={request.accepted ? true : request.denied} id={JSON.stringify(request)} onClick={this.sendDeniedID}>Deny</Button>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export class CurrentFlowComp extends Component{
    constructor(props){
        super(props);

        this.state = {
            cfsValue: this.props.cfsValue,
            currentFlow: 0,
            difference: 0,
            meter: this.props.meter,
            loading: true,
            notupdated: true
        }
    }

    componentDidMount(){
        axios.get(`https://webservices.mwdsc.org/wins/Public/api/wocurrentflow/${this.state.meter}?type=json`,{
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            var diff = this.state.cfsValue - Number(res.data[0].Flow).toFixed(1);
            this.setState({currentFlow: res.data[0].Flow, difference: diff, loading: false})
        })
    }

    render(){
        return(
            <p>{this.state.loading ? <Spinner size="sm" color="secondary"/> : this.state.currentFlow + '(' + this.state.difference.toFixed(1) + ')'}</p>
        )
    }
}

export class ErrorModal extends Component {
    constructor(props) {
        super(props);
    }

    render(){
    return (<div>
        <Modal isOpen={this.props.modal}>
        <ModalHeader>Bad Request</ModalHeader>
        <ModalBody>{this.props.message}</ModalBody>
        <ModalFooter>
            <Button color="success" onClick={this.props.confirmCancel}>OK</Button>
        </ModalFooter>
        </Modal>
    </div>)
    }
}

export class LoadingModal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return(
            <div>
                <Modal isOpen={this.props.modal}>
                    <ModalHeader>Attempting to save data</ModalHeader>
                        <ModalBody>Currently trying to save the data
                        </ModalBody>
                </Modal>
            </div>
        )
    }
}

export class RebuttalModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            id: this.props.request._id,
            name: this.props.request.meter,
            agency: this.props.request.agency,
            cfsValue: this.props.request.cfsValue,
            scheduleDate: this.props.request.scheduleDate,
            scheduleTime: this.props.request.scheduleTime,
            comments: this.props.request.comments,
            rebuttal: this.props.request.rebuttal,
            acknowledged: this.props.request.acknowledged
        };

        this.rebuttalClick = this.rebuttalClick.bind(this);
        this.rebuttalCancel = this.rebuttalCancel.bind(this);
    }

    componentDidMount() {
        /*axios.get(`${ServerUrl}/api/findbyid/${this.props._id}`,{
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer ' + Auth.getToken()
            }
        })
        .then(res => this.setState({
            id: res.data._id,
            name: res.data.meter,
            agency: res.data.agency,
            cfsValue: res.data.cfsValue,
            scheduleDate: res.data.scheduleDate,
            scheduleTime: res.data.scheduleTime,
            comments: res.data.comments,
            rebuttal: res.data.rebuttal,
            acknowledged: res.data.acknowledged,
            accepted: res.data.accepted
        }))*/
    }

    componentWillReceiveProps(newProps) {
        if (newProps.id !== this.props._id){
            this.setState({id: newProps.id})
        }
    }

    handleInput = event => {
        this.setState({
          [event.target.id]: event.target.value
        })
      }

    rebuttalClick() {
        //console.log("ID: " + this.state.id + " Comments: " + this.state.comments + " rebuttal: " + this.state.rebuttal + " acknowledged: " + this.state.acknowledged)
        this.props.rebuttalClick(this.state.id, this.state.comments, this.state.rebuttal, this.state.accepted, this.state.acknowledged);
    }

    rebuttalCancel(){
        this.props.rebuttalCancel();
    }

    render() {

        var confirmText = <div>
            <br /><p>Agency: {this.state.agency}</p>
            <br /><p>Meter: {this.state.name}</p>
            <br /><p>CFS: {this.state.cfsValue}</p>
            <br /><p>Schedule Date: {this.state.scheduleDate}</p>
            <br /><p>Schedule Time: {this.state.scheduleTime}</p>
            <br /><p>Comments: {this.state.comments}</p>
        </div>

        return(
            <div>
                <Modal isOpen={this.props.modal}>
                    <ModalHeader>Send a response about the following request:</ModalHeader>
                        <ModalBody>{confirmText}
                            <Input type="textarea" id="rebuttal" onChange={this.handleInput} value={this.state.rebuttal}></Input>
                        </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.rebuttalClick}>Confirm</Button>
                        <Button color="danger" onClick={this.rebuttalCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export class AcceptModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            id: this.props.request._id,
            name: this.props.request.meter,
            agency: this.props.request.agency,
            cfsValue: this.props.request.cfsValue,
            scheduleDate: this.props.request.scheduleDate,
            scheduleTime: this.props.request.scheduleTime,
            comments: this.props.request.comments,
            rebuttal: this.props.request.rebuttal,
            acknowledged: this.props.request.acknowledged
        };

        this.acceptClick = this.acceptClick.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        if (newProps.id !== this.props._id){
            this.setState({id: newProps.id})
        }
    }

    handleInput = event => {
        this.setState({
          [event.target.id]: event.target.value
        })
      }

    acceptClick() {
        //console.log("ID: " + this.state.id + " Comments: " + this.state.comments + " rebuttal: " + this.state.rebuttal)
        this.props.acceptClick(this.state.id, this.state.comments, this.state.rebuttal, this.state.acknowledged);
    }

    render() {

        var confirmText = <div>
            <br /><p>Agency: {this.state.agency}</p>
            <br /><p>Meter: {this.state.name}</p>
            <br /><p>CFS: {this.state.cfsValue}</p>
            <br /><p>Schedule Date: {this.state.scheduleDate}</p>
            <br /><p>Schedule Time: {this.state.scheduleTime}</p>
            <br /><p>Comments: {this.state.comments}</p>
        </div>

        return(
            <div>
                <Modal isOpen={this.props.modal}>
                    <ModalHeader>Do you want to accept the following request?:</ModalHeader>
                        <ModalBody>{confirmText}
                        </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.acceptClick}>Confirm</Button>
                        <Button color="danger" onClick={this.props.acceptCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export class DenyModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            id: this.props.request._id,
            name: this.props.request.meter,
            agency: this.props.request.agency,
            cfsValue: this.props.request.cfsValue,
            scheduleDate: this.props.request.scheduleDate,
            scheduleTime: this.props.request.scheduleTime,
            comments: this.props.request.comments,
            rebuttal: this.props.request.rebuttal,
            acknowledged: this.props.request.acknowledged
        };

        this.denyClick = this.denyClick.bind(this);
    }

    componentDidMount() {
        /*axios.get(`${ServerUrl}/api/findbyid/${this.props.id}`,{
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken() 
            }
        })
        .then(res => this.setState({
            id: res.data._id,
            name: res.data.meter,
            agency: res.data.agency,
            cfsValue: res.data.cfsValue,
            scheduleDate: res.data.scheduleDate,
            scheduleTime: res.data.scheduleTime,
            comments: res.data.comments,
            rebuttal: res.data.rebuttal,
            acknowledged: res.data.acknowledged
        }))*/
    }

    componentWillReceiveProps(newProps) {
        if (newProps.id !== this.props.id){
            this.setState({id: newProps.id})
        }
    }

    handleInput = event => {
        this.setState({
          [event.target.id]: event.target.value
        })
      }

    denyClick() {
        //console.log("ID: " + this.state.id + " Comments: " + this.state.comments + " rebuttal: " + this.state.rebuttal)
        this.props.denyClick(this.state.id, this.state.comments, this.state.rebuttal, this.state.acknowledged);
    }

    render() {

        var confirmText = <div>
            <br /><p>Agency: {this.state.agency}</p>
            <br /><p>Meter: {this.state.name}</p>
            <br /><p>CFS: {this.state.cfsValue}</p>
            <br /><p>Schedule Date: {this.state.scheduleDate}</p>
            <br /><p>Schedule Time: {this.state.scheduleTime}</p>
            <br /><p>Comments: {this.state.comments}</p>
        </div>

        return(
            <div>
                <Modal isOpen={this.props.modal}>
                    <ModalHeader>Deny the following request?</ModalHeader>
                        <ModalBody>{confirmText}
                        </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.denyClick}>Confirm</Button>
                        <Button color="danger" onClick={this.props.denyCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export class CompleteModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            id: this.props.request._id,
            name: this.props.request.meter,
            agency: this.props.request.agency,
            cfsValue: this.props.request.cfsValue,
            scheduleDate: this.props.request.scheduleDate,
            scheduleTime: this.props.request.scheduleTime,
            comments: this.props.request.comments,
            rebuttal: this.props.request.rebuttal,
            acknowledged: this.props.request.acknowledged
        };

        this.completeClick = this.completeClick.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        if (newProps.id !== this.props._id){
            this.setState({id: newProps.id})
        }
    }

    handleInput = event => {
        this.setState({
          [event.target.id]: event.target.value
        })
      }

    completeClick() {
        this.props.completeClick(this.state.id);
    }

    render() {

        var confirmText = <div>
            <br /><p>Agency: {this.state.agency}</p>
            <br /><p>Meter: {this.state.name}</p>
            <br /><p>CFS: {this.state.cfsValue}</p>
            <br /><p>Schedule Date: {this.state.scheduleDate}</p>
            <br /><p>Schedule Time: {this.state.scheduleTime}</p>
            <br /><p>Comments: {this.state.comments}</p>
        </div>

        return(
            <div>
                <Modal isOpen={this.props.modal}>
                    <ModalHeader>Send the following request to ESL?</ModalHeader>
                        <ModalBody>{confirmText}
                        </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.completeClick}>Confirm</Button>
                        <Button color="danger" onClick={this.props.completeCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export class FacilityModal extends Component {
    constructor(props) {
        super(props); 
    }

    render(){
        return (<div>
            <Modal isOpen={this.props.modal}>
            <ModalHeader>Facility</ModalHeader>
            <ModalBody>Select the facility you want to monitor</ModalBody>
            <ModalFooter>
                <Button color="success" onClick={this.props.setFacility.bind(this,"OCC")}>OCC</Button>
                <Button color="success" onClick={this.props.setFacility.bind(this,"Mills")}>Mills Plant</Button>
                <Button color="success" onClick={this.props.setFacility.bind(this,"Skinner")}>Skinner Plant</Button>
            </ModalFooter>
            </Modal>
        </div>)
    }
}

export default { RequestsTable, RebuttalModal, AcceptModal, DenyModal, CompleteModal, LoadingModal, FacilityModal };